var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "overlay"
    },
    on: {
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    key: _vm.updater,
    staticClass: "panel-wrapper"
  }, [_c('div', {
    staticClass: "add-user-panel-header"
  }, [_vm.feedbackOnly ? _c('h4', [_vm._v(_vm._s(_vm.$t("MyFeedback")))]) : _vm._e(), _vm.achievementsOnly ? _c('h4', [_vm._v(_vm._s(_vm.$t("Achievements")))]) : _vm._e(), _vm.edit && !_vm.feedbackOnly && !_vm.achievementsOnly ? _c('h4', [_vm._v(" " + _vm._s(_vm.$t("UpdateUser")) + " ")]) : _vm._e(), !_vm.edit ? _c('h4', [_vm._v(_vm._s(_vm.$t("AddNewUser")))]) : _vm._e()]), !_vm.feedbackOnly && !_vm.achievementsOnly ? _c('div', {
    staticClass: "add-user-panel-content"
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "firstName",
      "error": _vm.errors && !_vm.newUser.firstName,
      "required": true,
      "placeholder": _vm.$t('FirstName')
    },
    model: {
      value: _vm.newUser.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "firstName", $$v);
      },
      expression: "newUser.firstName"
    }
  })], 1), _c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "lastName",
      "error": _vm.errors && !_vm.newUser.lastName,
      "required": true,
      "placeholder": _vm.$t('LastName')
    },
    model: {
      value: _vm.newUser.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "lastName", $$v);
      },
      expression: "newUser.lastName"
    }
  })], 1)], 1), !_vm.userSettings ? _c('v-row', [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "email",
      "error": _vm.errors && !_vm.newUser.email,
      "required": true,
      "icon-left": 'email_20_20.svg',
      "placeholder": _vm.$t('UserEmail')
    },
    model: {
      value: _vm.newUser.email,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "email", $$v);
      },
      expression: "newUser.email"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "phoneNumber",
      "error": _vm.errors && !_vm.newUser.phoneNumber,
      "required": true,
      "icon-left": 'phone_20_20.svg',
      "placeholder": _vm.$t('PhoneNum')
    },
    model: {
      value: _vm.newUser.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "phoneNumber", $$v);
      },
      expression: "newUser.phoneNumber"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "personalCode",
      "placeholder": _vm.$t('PersonalCode')
    },
    model: {
      value: _vm.newUser.personalCode,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "personalCode", $$v);
      },
      expression: "newUser.personalCode"
    }
  })], 1)], 1), !_vm.userSettings ? _c('div', {
    staticClass: "user-role-section",
    class: {
      'red-border': _vm.errors && _vm.errors.types
    },
    staticStyle: {
      "background": "#ffffff",
      "height": "96px",
      "padding": "8px 12px",
      "border-radius": "8px",
      "gap": "8px",
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_c('v-row', {
    staticClass: "required font-small",
    staticStyle: {
      "display": "flex",
      "align-items": "flex-start"
    }
  }, [_c('p', {
    staticClass: "label11 gray-font"
  }, [_vm._v(_vm._s(_vm.$t("Role")))])]), _c('v-row', {
    staticClass: "checkboxes",
    staticStyle: {
      "display": "flex",
      "margin-top": "8px",
      "gap": "12px 24px"
    }
  }, _vm._l(_vm.newUser.types, function (type, index) {
    return _c('div', {
      key: index,
      staticClass: "checkbox-item",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          type.value = !type.value;
        }
      }
    }, [_c('Checkbox', {
      attrs: {
        "checked": type.value,
        "admin-module": true,
        "customStyle": 'width: 24px; height: 24px; margin: 0'
      },
      on: {
        "clicked": function ($event) {
          type.value = !type.value;
        }
      }
    }), _c('p', {
      staticClass: "content"
    }, [_vm._v(_vm._s(_vm.$t(index)))])], 1);
  }), 0)], 1) : _vm._e(), _vm.errors && _vm.errors.types ? _c('div', {
    staticClass: "content",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(this.$t("FormErrors." + _vm.errors.types)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "user-language-section",
    staticStyle: {
      "background": "#ffffff",
      "padding": "8px 12px",
      "border-radius": "8px",
      "gap": "8px",
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_c('v-row', {
    staticClass: "label11 gray-font",
    staticStyle: {
      "display": "flex",
      "align-items": "flex-start"
    }
  }, [_vm._v(_vm._s(_vm.$t("Languages")))]), _c('v-row', {
    staticClass: "checkboxes",
    staticStyle: {
      "display": "flex",
      "margin-top": "8px",
      "gap": "12px 24px"
    }
  }, _vm._l(_vm.newUser.languages, function (language, index) {
    return _c('div', {
      key: index,
      staticClass: "checkbox-item",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.changeLanguageValue(language, index);
        }
      }
    }, [_c('Checkbox', {
      attrs: {
        "checked": language,
        "admin-module": true,
        "customStyle": 'width: 24px; height: 24px; margin: 0'
      },
      on: {
        "clicked": function ($event) {
          return _vm.changeLanguageValue(language, index);
        }
      }
    }), _c('p', {
      staticClass: "content"
    }, [_vm._v(_vm._s(_vm.$t(index.toUpperCase())))])], 1);
  }), 0)], 1), _vm.companyGroups.length > 0 && !_vm.userSettings ? _c('v-row', [_c('v-col', [_c('DropdownMulti', {
    attrs: {
      "items": _vm.companyGroups,
      "selected-items": _vm.newUser.groups,
      "height": '52px',
      "display-key": 'name',
      "value-key": 'name',
      "field-type": _vm.$t('Group'),
      "required": true,
      "enable-search": true,
      "error": _vm.errors && _vm.newUser.groups.length < 1,
      "access-level": _vm.user.access.accessLevel,
      "accessItems": _vm.user.access.groups
    },
    on: {
      "update:filteredItems": _vm.updateSelectedItems,
      "update:validity": _vm.handleValidity
    }
  })], 1)], 1) : _vm._e(), _vm.newUser.groups.length > 0 && !_vm.userSettings ? _c('v-row', {
    key: 'group-' + _vm.groupUpdater
  }, [_c('BaseDropdown', {
    attrs: {
      "placeholder": _vm.$t('defaultGroup'),
      "dropdown-offset-y": 60,
      "items": _vm.newUser.groups,
      "required": "true",
      "error": _vm.errors && _vm.errors.defaultGroupValue,
      "error-message": _vm.errors && !_vm.defaultGroup ? _vm.$t('FormErrors.' + _vm.errors.defaultGroupValue) : '',
      "return-object": true,
      "dropdown-allow-null": false,
      "show-dropdown-arrow": true,
      "type": "dropdown"
    },
    model: {
      value: _vm.defaultGroup,
      callback: function ($$v) {
        _vm.defaultGroup = $$v;
      },
      expression: "defaultGroup"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "12px"
    }
  }, [!_vm.edit || _vm.edit && _vm.editableUser._id !== _vm.user._id ? _c('v-col', [_c('BaseDropdown', {
    attrs: {
      "id": 'add-user-sidepanel-role',
      "items": _vm.roles,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('AccessLevel'),
      "show-dropdown-arrow": true,
      "error": _vm.errors && !_vm.newUser.role
    },
    model: {
      value: _vm.newUser.role,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "role", $$v);
      },
      expression: "newUser.role"
    }
  })], 1) : _vm._e(), _vm.edit && _vm.newUser.userStatus !== 'Invited' && _vm.editableUser._id !== _vm.user._id ? _c('v-col', [_c('BaseDropdown', {
    attrs: {
      "id": 'adduser-sidepanel-userStatus',
      "items": _vm.userStatuses,
      "value": "value",
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Status'),
      "show-dropdown-arrow": true,
      "error": _vm.errors && !_vm.newUser.userStatus
    },
    model: {
      value: _vm.newUser.userStatus,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "userStatus", $$v);
      },
      expression: "newUser.userStatus"
    }
  })], 1) : _vm._e()], 1), _vm.newUser.groups.length > 0 && _vm.roles.length && _vm.newUser.role === _vm.roles[1].value && !_vm.userSettings ? _c('v-row', {
    key: 'multi-group' + _vm.groupUpdater
  }, [_c('v-col', [_c('DropdownMulti', {
    attrs: {
      "items": _vm.groupAdminSelection,
      "selected-items": _vm.groupAdminGroups,
      "height": '52px',
      "display-key": 'name',
      "value-key": 'name',
      "field-type": _vm.$t('AdminInGroups'),
      "required": true,
      "enable-search": true,
      "error": _vm.errors && _vm.groupAdminGroups.length < 1
    },
    on: {
      "update:filteredItems": _vm.updateGroupAdminGroups,
      "update:validity": _vm.handleValidity
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    class: {
      'margin-up': _vm.user.isAdmin
    },
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "personalWWWPage",
      "placeholder": _vm.$t('PersonalWWWPage')
    },
    model: {
      value: _vm.newUser.personalWWWPage,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "personalWWWPage", $$v);
      },
      expression: "newUser.personalWWWPage"
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "facebook",
      "placeholder": _vm.$t('Facebook')
    },
    model: {
      value: _vm.newUser.socials.facebook,
      callback: function ($$v) {
        _vm.$set(_vm.newUser.socials, "facebook", $$v);
      },
      expression: "newUser.socials.facebook"
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "instagram",
      "placeholder": _vm.$t('Instagram')
    },
    model: {
      value: _vm.newUser.socials.instagram,
      callback: function ($$v) {
        _vm.$set(_vm.newUser.socials, "instagram", $$v);
      },
      expression: "newUser.socials.instagram"
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "linkedIn",
      "placeholder": _vm.$t('LinkedIn')
    },
    model: {
      value: _vm.newUser.socials.linkedIn,
      callback: function ($$v) {
        _vm.$set(_vm.newUser.socials, "linkedIn", $$v);
      },
      expression: "newUser.socials.linkedIn"
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "tiktok",
      "placeholder": _vm.$t('TikTok')
    },
    model: {
      value: _vm.newUser.socials.tiktok,
      callback: function ($$v) {
        _vm.$set(_vm.newUser.socials, "tiktok", $$v);
      },
      expression: "newUser.socials.tiktok"
    }
  })], 1)], 1) : _vm._e(), _vm.userSettings ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "youtube",
      "placeholder": _vm.$t('Youtube')
    },
    model: {
      value: _vm.newUser.socials.youtube,
      callback: function ($$v) {
        _vm.$set(_vm.newUser.socials, "youtube", $$v);
      },
      expression: "newUser.socials.youtube"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "language-selection"
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'est',
      '': _vm.activeView !== 'est'
    },
    attrs: {
      "id": "estonianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'est';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstonianLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'eng',
      '': _vm.activeView !== 'eng'
    },
    attrs: {
      "id": "englishPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'eng';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnglishLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'rus',
      '': _vm.activeView !== 'rus'
    },
    attrs: {
      "id": "russianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'rus';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("RussianLanguage")) + " ")])])]), _vm.user.isAdmin ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', [_c('BaseInput', {
    attrs: {
      "id": "occupation",
      "placeholder": _vm.$t('Occupation')
    },
    model: {
      value: _vm.newUser.occupation[_vm.activeView],
      callback: function ($$v) {
        _vm.$set(_vm.newUser.occupation, _vm.activeView, $$v);
      },
      expression: "newUser.occupation[activeView]"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    class: {
      'margin-up': _vm.user.isAdmin
    }
  }, [_c('BaseTextarea', {
    attrs: {
      "id": 'description-user',
      "placeholder": _vm.$t('Description')
    },
    model: {
      value: _vm.newUser.about[_vm.activeView],
      callback: function ($$v) {
        _vm.$set(_vm.newUser.about, _vm.activeView, $$v);
      },
      expression: "newUser.about[activeView]"
    }
  })], 1), _c('div', {
    staticClass: "profile-picture-container"
  }, [!_vm.edit ? _c('UploadImages', {
    class: {
      'image-uploader small-uploader-et': _vm.newUser.images.length < 1 && _vm.$i18n.locale === 'et',
      'image-uploader small-uploader-en': _vm.newUser.images.length < 1 && _vm.$i18n.locale === 'en',
      'image-uploader ': _vm.newUser.images.length >= 1
    },
    attrs: {
      "upload-msg": _vm.$t('UploadImage'),
      "Imgs": _vm.newUser.images,
      "max": 1
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.edit ? _c('UploadImages', {
    class: {
      'image-uploader small-uploader-et': _vm.newUser.images.length < 1 && _vm.$i18n.locale === 'et',
      'image-uploader small-uploader-en': _vm.newUser.images.length < 1 && _vm.$i18n.locale === 'en',
      'image-uploader ': _vm.newUser.images.length >= 1
    },
    attrs: {
      "upload-msg": _vm.$t('UploadImage'),
      "max": 1,
      "Imgs2": _vm.editableUser.profilePicture
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.feedbackOnly && !_vm.achievementsOnly ? _c('div', {
    staticClass: "feedback-content",
    staticStyle: {
      "margin": "121px 32px 136px 32px"
    }
  }, [_c('div', {
    staticClass: "language-selection",
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'est',
      '': _vm.activeView !== 'est'
    },
    attrs: {
      "id": "estonianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'est';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstonianLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'eng',
      '': _vm.activeView !== 'eng'
    },
    attrs: {
      "id": "englishPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'eng';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnglishLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'rus',
      '': _vm.activeView !== 'rus'
    },
    attrs: {
      "id": "russianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'rus';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("RussianLanguage")) + " ")])])]), _c('div', [_c('BaseTextarea', {
    style: 'height: 670px',
    attrs: {
      "id": 'feedback-user'
    },
    model: {
      value: _vm.newUser.clients[_vm.activeView],
      callback: function ($$v) {
        _vm.$set(_vm.newUser.clients, _vm.activeView, $$v);
      },
      expression: "newUser.clients[activeView]"
    }
  })], 1)]) : _vm.achievementsOnly ? _c('div', {
    staticClass: "achievements-content",
    staticStyle: {
      "margin": "121px 32px 136px 32px"
    }
  }, [_c('div', {
    staticClass: "language-selection",
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'est',
      '': _vm.activeView !== 'est'
    },
    attrs: {
      "id": "estonianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'est';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstonianLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'eng',
      '': _vm.activeView !== 'eng'
    },
    attrs: {
      "id": "englishPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'eng';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnglishLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'rus',
      '': _vm.activeView !== 'rus'
    },
    attrs: {
      "id": "russianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'rus';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("RussianLanguage")) + " ")])])]), _c('div', [_c('BaseTextarea', {
    style: 'height: 670px',
    attrs: {
      "id": 'feedback-user'
    },
    model: {
      value: _vm.newUser.achievements[_vm.activeView],
      callback: function ($$v) {
        _vm.$set(_vm.newUser.achievements, _vm.activeView, $$v);
      },
      expression: "newUser.achievements[activeView]"
    }
  })], 1)]) : _vm._e(), _c('div', {
    class: {
      'text-success': _vm.formMessage.color === 'green',
      'text-error': _vm.formMessage.color === 'red'
    },
    staticStyle: {
      "margin": "16px 32px 0px 32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formMessage.text) + " ")]), _c('div', {
    staticClass: "add-user-buttons"
  }, [_c('button', {
    staticClass: "cancel",
    staticStyle: {
      "padding-left": "12px",
      "padding-right": "12px",
      "margin-right": "8px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('closed');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticClass: "add-button",
    on: {
      "click": _vm.submitUser
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Submit")))]) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }