<template>
  <div @click="closePanel($event)" id="overlay">
    <div :key="updater" class="panel-wrapper">
      <div class="add-user-panel-header">
        <h4 v-if="feedbackOnly">{{ $t("MyFeedback") }}</h4>
        <h4 v-if="achievementsOnly">{{ $t("Achievements") }}</h4>
        <h4 v-if="edit && !feedbackOnly && !achievementsOnly">
          {{ $t("UpdateUser") }}
        </h4>
        <h4 v-if="!edit">{{ $t("AddNewUser") }}</h4>
      </div>
      <div
        v-if="!feedbackOnly && !achievementsOnly"
        class="add-user-panel-content"
      >
        <v-row
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 12px;
          "
        >
          <v-col>
            <BaseInput
              id="firstName"
              v-model="newUser.firstName"
              :error="errors && !newUser.firstName"
              :required="true"
              :placeholder="$t('FirstName')"
            />
          </v-col>
          <v-col>
            <BaseInput
              id="lastName"
              v-model="newUser.lastName"
              :error="errors && !newUser.lastName"
              :required="true"
              :placeholder="$t('LastName')"
            />
          </v-col>
        </v-row>
        <v-row v-if="!userSettings">
          <v-col>
            <BaseInput
              id="email"
              v-model="newUser.email"
              :error="errors && !newUser.email"
              :required="true"
              :icon-left="'email_20_20.svg'"
              :placeholder="$t('UserEmail')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <BaseInput
              id="phoneNumber"
              v-model="newUser.phoneNumber"
              :error="errors && !newUser.phoneNumber"
              :required="true"
              :icon-left="'phone_20_20.svg'"
              :placeholder="$t('PhoneNum')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <BaseInput
              id="personalCode"
              v-model="newUser.personalCode"
              :placeholder="$t('PersonalCode')"
            />
          </v-col>
        </v-row>
        <!-- <v-row v-if="groups.length > 0"> -->
        <div
          v-if="!userSettings"
          class="user-role-section"
          style="
            background: #ffffff;
            height: 96px;
            padding: 8px 12px;
            border-radius: 8px;
            gap: 8px;
            margin-top: 12px;
            margin-bottom: 12px;
          "
          v-bind:class="{ 'red-border': errors && errors.types }"
        >
          <v-row
            class="required font-small"
            style="display: flex; align-items: flex-start"
            ><p class="label11 gray-font">{{ $t("Role") }}</p></v-row
          >
          <v-row
            class="checkboxes"
            style="
              display: flex;
              margin-top: 0px;
              margin-top: 8px;
              gap: 12px 24px;
            "
          >
            <div
              v-for="(type, index) in newUser.types"
              :key="index"
              class="checkbox-item"
              @click="type.value = !type.value"
              style="cursor: pointer"
            >
              <Checkbox
                @clicked="type.value = !type.value"
                :checked="type.value"
                :admin-module="true"
                :customStyle="'width: 24px; height: 24px; margin: 0'"
              />
              <p class="content">{{ $t(index) }}</p>
            </div>
          </v-row>
        </div>
        <div class="content" style="color: red" v-if="errors && errors.types">
          {{ this.$t("FormErrors." + errors.types) }}
        </div>
        <div
          class="user-language-section"
          style="
            background: #ffffff;
            padding: 8px 12px;
            border-radius: 8px;
            gap: 8px;
            margin-top: 12px;
            margin-bottom: 12px;
          "
        >
          <v-row
            class="label11 gray-font"
            style="display: flex; align-items: flex-start"
            >{{ $t("Languages") }}</v-row
          >
          <v-row
            class="checkboxes"
            style="
              display: flex;
              margin-top: 0px;
              gap: 12px 24px;
              margin-top: 8px;
            "
          >
            <div
              v-for="(language, index) in newUser.languages"
              :key="index"
              class="checkbox-item"
              style="cursor: pointer"
              @click="changeLanguageValue(language, index)"
            >
              <Checkbox
                @clicked="changeLanguageValue(language, index)"
                :checked="language"
                :admin-module="true"
                :customStyle="'width: 24px; height: 24px; margin: 0'"
              />
              <p class="content">{{ $t(index.toUpperCase()) }}</p>
            </div>
          </v-row>
        </div>
        <v-row v-if="companyGroups.length > 0 && !userSettings">
          <!--          <BaseDropdown
            :id="'add-user-sidepanel-group'"
            :items="groups"
            v-model="newUser.group"
            :dropdown-allow-null="false"
            :placeholder="$t('Group')"
            :show-dropdown-arrow="true"
            :error="errors && !newUser.group"
          />-->
          <v-col>
            <DropdownMulti
              :items="companyGroups"
              :selected-items="newUser.groups"
              :height="'52px'"
              :display-key="'name'"
              :value-key="'name'"
              :field-type="$t('Group')"
              :required="true"
              :enable-search="true"
              :error="errors && newUser.groups.length < 1"
              :access-level="user.access.accessLevel"
              :accessItems="user.access.groups"
              @update:filteredItems="updateSelectedItems"
              @update:validity="handleValidity"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="newUser.groups.length > 0 && !userSettings"
          :key="'group-' + groupUpdater"
        >
          <BaseDropdown
            :placeholder="$t('defaultGroup')"
            :dropdown-offset-y="60"
            v-model="defaultGroup"
            :items="newUser.groups"
            required="true"
            :error="errors && errors.defaultGroupValue"
            :error-message="
              errors && !defaultGroup
                ? $t('FormErrors.' + errors.defaultGroupValue)
                : ''
            "
            :return-object="true"
            :dropdown-allow-null="false"
            :show-dropdown-arrow="true"
            type="dropdown"
          />
        </v-row>
        <!--  <v-row v-if="!edit || (edit && editableUser._id !== user._id)">
          <BaseDropdown
            :id="'add-user-sidepanel-role'"
            :items="roles"
            v-model="newUser.role"
            :dropdown-allow-null="false"
            :placeholder="$t('Role')"
            :show-dropdown-arrow="true"
            :error="errors && !newUser.role"
          />
        </v-row>
        <v-row
          v-if="newUser.groups.length > 0 && newUser.role === roles[1].value"
          :key="'multi-group' + groupUpdater"
        >
          <v-col>
            <DropdownMulti
              :items="groupAdminSelection"
              :selected-items="groupAdminGroups"
              :field-type="$t('AdminInGroups')"
              :height="'52px'"
              :display-key="'name'"
              :value-key="'value._id'"
              :error="errors && groupAdminGroups.length < 1"
              :required="true"
              @update:filteredItems="updateGroupAdminGroups"
              @update:validity="handleValidity"
            />
          </v-col>
        </v-row>
        <v-row>
          <BaseInput
            v-model="newUser.listingAccounts[0].email"
            :placeholder="$t('kv24_username')"
          />
        </v-row> -->
        <!--   <div
            v-if="
            edit &&
            newUser.userStatus !== 'Invited' &&
            editableUser._id !== user._id
          "
          style="margin-top: 12px"
        >
          <BaseDropdown
            :id="'adduser-sidepanel-userStatus'"
            :items="userStatuses"
            value="value"
            v-model="newUser.userStatus"
            :dropdown-allow-null="false"
            :placeholder="$t('Status')"
            :show-dropdown-arrow="true"
            :error="errors && !newUser.userStatus"
          />
        </div> -->
        <v-row style="gap: 8px; margin-top: 12px">
          <v-col v-if="!edit || (edit && editableUser._id !== user._id)">
            <BaseDropdown
              :id="'add-user-sidepanel-role'"
              :items="roles"
              v-model="newUser.role"
              :dropdown-allow-null="false"
              :placeholder="$t('AccessLevel')"
              :show-dropdown-arrow="true"
              :error="errors && !newUser.role"
            />
          </v-col>
          <v-col
            v-if="
              edit &&
              newUser.userStatus !== 'Invited' &&
              editableUser._id !== user._id
            "
          >
            <BaseDropdown
              :id="'adduser-sidepanel-userStatus'"
              :items="userStatuses"
              value="value"
              v-model="newUser.userStatus"
              :dropdown-allow-null="false"
              :placeholder="$t('Status')"
              :show-dropdown-arrow="true"
              :error="errors && !newUser.userStatus"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="
            newUser.groups.length > 0 &&
            roles.length &&
            newUser.role === roles[1].value &&
            !userSettings
          "
          :key="'multi-group' + groupUpdater"
        >
          <v-col>
            <DropdownMulti
              :items="groupAdminSelection"
              :selected-items="groupAdminGroups"
              :height="'52px'"
              :display-key="'name'"
              :value-key="'name'"
              :field-type="$t('AdminInGroups')"
              :required="true"
              :enable-search="true"
              :error="errors && groupAdminGroups.length < 1"
              @update:filteredItems="updateGroupAdminGroups"
              @update:validity="handleValidity"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="userSettings"
          style="margin-top: 0px"
          v-bind:class="{ 'margin-up': user.isAdmin }"
        >
          <v-col>
            <BaseInput
              id="personalWWWPage"
              v-model="newUser.personalWWWPage"
              :placeholder="$t('PersonalWWWPage')"
            />
          </v-col>
        </v-row>
        <v-row v-if="userSettings" style="margin-top: 12px">
          <v-col>
            <BaseInput
              id="facebook"
              v-model="newUser.socials.facebook"
              :placeholder="$t('Facebook')"
            />
          </v-col>
        </v-row>
        <v-row v-if="userSettings" style="margin-top: 12px">
          <v-col>
            <BaseInput
              id="instagram"
              v-model="newUser.socials.instagram"
              :placeholder="$t('Instagram')"
            />
          </v-col>
        </v-row>
        <v-row v-if="userSettings" style="margin-top: 12px">
          <v-col>
            <BaseInput
              id="linkedIn"
              v-model="newUser.socials.linkedIn"
              :placeholder="$t('LinkedIn')"
            />
          </v-col>
        </v-row>
        <v-row v-if="userSettings" style="margin-top: 12px">
          <v-col>
            <BaseInput
              id="tiktok"
              v-model="newUser.socials.tiktok"
              :placeholder="$t('TikTok')"
            />
          </v-col>
        </v-row>
        <v-row v-if="userSettings" style="margin-top: 12px">
          <v-col>
            <BaseInput
              id="youtube"
              v-model="newUser.socials.youtube"
              :placeholder="$t('Youtube')"
            />
          </v-col>
        </v-row>
        <div class="language-selection">
          <div
            v-bind:class="{
              'active-view': activeView === 'est',
              '': activeView !== 'est',
            }"
            @click="activeView = 'est'"
            id="estonianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EstonianLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'eng',
              '': activeView !== 'eng',
            }"
            @click="activeView = 'eng'"
            id="englishPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EnglishLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'rus',
              '': activeView !== 'rus',
            }"
            @click="activeView = 'rus'"
            id="russianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("RussianLanguage") }}
            </p>
          </div>
        </div>
        <v-row v-if="user.isAdmin" style="margin-top: 0px">
          <v-col>
            <BaseInput
              id="occupation"
              v-model="newUser.occupation[activeView]"
              :placeholder="$t('Occupation')"
            />
          </v-col>
        </v-row>
        <div v-bind:class="{ 'margin-up': user.isAdmin }">
          <BaseTextarea
            :id="'description-user'"
            v-model="newUser.about[activeView]"
            :placeholder="$t('Description')"
          />
        </div>
        <div class="profile-picture-container">
          <UploadImages
            v-if="!edit"
            :upload-msg="$t('UploadImage')"
            :Imgs="newUser.images"
            :max="1"
            @changed="putImages($event)"
            v-bind:class="{
              'image-uploader small-uploader-et':
                newUser.images.length < 1 && $i18n.locale === 'et',
              'image-uploader small-uploader-en':
                newUser.images.length < 1 && $i18n.locale === 'en',
              'image-uploader ': newUser.images.length >= 1,
            }"
          />
          <UploadImages
            v-if="edit"
            :upload-msg="$t('UploadImage')"
            :max="1"
            :Imgs2="editableUser.profilePicture"
            @changed="putImages($event)"
            v-bind:class="{
              'image-uploader small-uploader-et':
                newUser.images.length < 1 && $i18n.locale === 'et',
              'image-uploader small-uploader-en':
                newUser.images.length < 1 && $i18n.locale === 'en',
              'image-uploader ': newUser.images.length >= 1,
            }"
          />
        </div>
      </div>
      <div
        v-if="feedbackOnly && !achievementsOnly"
        class="feedback-content"
        style="margin: 121px 32px 136px 32px"
      >
        <div class="language-selection" style="padding-top: 0px">
          <div
            v-bind:class="{
              'active-view': activeView === 'est',
              '': activeView !== 'est',
            }"
            @click="activeView = 'est'"
            id="estonianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EstonianLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'eng',
              '': activeView !== 'eng',
            }"
            @click="activeView = 'eng'"
            id="englishPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EnglishLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'rus',
              '': activeView !== 'rus',
            }"
            @click="activeView = 'rus'"
            id="russianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("RussianLanguage") }}
            </p>
          </div>
        </div>
        <div>
          <BaseTextarea
            :id="'feedback-user'"
            v-model="newUser.clients[activeView]"
            :style="'height: 670px'"
          />
        </div>
      </div>
      <div
        v-else-if="achievementsOnly"
        class="achievements-content"
        style="margin: 121px 32px 136px 32px"
      >
        <div class="language-selection" style="padding-top: 0px">
          <div
            v-bind:class="{
              'active-view': activeView === 'est',
              '': activeView !== 'est',
            }"
            @click="activeView = 'est'"
            id="estonianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EstonianLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'eng',
              '': activeView !== 'eng',
            }"
            @click="activeView = 'eng'"
            id="englishPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("EnglishLanguage") }}
            </p>
          </div>
          <div
            v-bind:class="{
              'active-view': activeView === 'rus',
              '': activeView !== 'rus',
            }"
            @click="activeView = 'rus'"
            id="russianPicker"
          >
            <p class="content-small semi-bold">
              {{ $t("RussianLanguage") }}
            </p>
          </div>
        </div>
        <div>
          <BaseTextarea
            :id="'feedback-user'"
            v-model="newUser.achievements[activeView]"
            :style="'height: 670px'"
          />
        </div>
      </div>

      <div
        style="margin: 16px 32px 0px 32px"
        v-bind:class="{
          'text-success': formMessage.color === 'green',
          'text-error': formMessage.color === 'red',
        }"
      >
        {{ formMessage.text }}
      </div>
      <div class="add-user-buttons">
        <button
          @click="$emit('closed')"
          class="cancel"
          style="padding-left: 12px; padding-right: 12px; margin-right: 8px"
        >
          {{ $t("Cancel") }}
        </button>
        <div class="add-button" @click="submitUser">
          <div
            style="position: relative; width: 30px; height: 30px"
            v-if="isLoading"
            class="loader"
          ></div>
          <img
            v-if="!isLoading"
            style="margin-right: 9px"
            alt=""
            src="../../assets/images/checkmarkCircled.svg"
          />
          <p v-if="!isLoading">{{ $t("Submit") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import UploadImages from "../../components/common/vue-upload-drop-images/dist/vue-upload-drop-images";
import BaseInput from "@/components/common/BaseInput";
import BaseDropdown from "@/components/common/BaseDropdown";
import DropdownMulti from "@/components/common/DropdownMulti";
import Checkbox from "../common/Checkbox";
import BaseTextarea from "@/components/common/BaseTextarea";

export default {
  name: "AddUserSidepanel",
  components: {
    BaseDropdown,
    BaseInput,
    UploadImages,
    DropdownMulti,
    Checkbox,
    BaseTextarea,
  },
  props: [
    "editableUser",
    "edit",
    "userSettings",
    "feedbackOnly",
    "achievementsOnly",
  ],
  data() {
    return {
      groupUpdater: 0,
      isLoading: false,
      temp: false,
      showErrors: false,
      errors: null,
      validate: [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "groups",
        "role",
        "types",
        "adminGroups",
        "defaultGroupValue",
      ],
      defaultGroup: null,
      groupAdminGroups: [],
      updater: 0,
      userStatuses: [
        { name: this.$t("Inactive"), value: "Inactive" },
        { name: this.$t("Active"), value: "Active" },
      ],
      activeView: "est",
      newUser: {
        firstName: "",
        lastName: "",
        personalCode: "",
        phoneNumber: "",
        email: "",
        groups: [],
        role: "broker",
        languages: {
          est: 0,
          eng: 0,
          rus: 0,
          fin: 0,
          ger: 0,
          fra: 0,
          spa: 0,
          por: 0,
          swe: 0,
        },
        types: {
          Broker: { value: false },
          Evaluator: { value: false },
          Consultant: { value: false },
          Administration: { value: false },
        },
        about: {
          est: "",
          eng: "",
          rus: "",
        },
        occupation: {
          est: "",
          eng: "",
          rus: "",
        },
        userStatus: "",
        listingAccounts: [
          {
            email: "",
            platform: "kv24",
          },
        ],
        images: [],
        personalWWWPage: null,
        clients: {
          est: "",
          eng: "",
          rus: "",
        },
        socials: {
          facebook: "",
          instagram: "",
          youtube: "",
          linkedIn: "",
          tiktok: "",
        },
        achievements: {
          est: "",
          eng: "",
          rus: "",
        },
      },
      formMessage: {
        text: "",
        color: "",
      },
      selectedItems: [],
      multiSelectValidity: true,
    };
  },
  created() {
    if (this.edit) {
      this.setupEditMode();
    } else {
      this.getGroups();
    }
  },
  computed: {
    ...mapGetters(["user"]),
    companyGroups() {
      console.log("COMPANY GROUPS COMPUTED!!!!!!")
      if (this.user.access.accessLevel === "company_admin") {
        const groups = [];
        for (const groupId in this.groups) {
          groups.push({
            name: this.groups[groupId].name,
            value: { _id: groupId, ...this.groups[groupId] },
          });
        }
        return groups;
      } else {
        const adminGroups = [];
        for (const groupId in this.user.access.groups) {
          const group = this.user.access.groups[groupId];
          if (group.isAdmin) {
            adminGroups.push({
              name: group.name,
              value: { _id: groupId, ...group },
            });
          }
        }
        if (this.edit) {
          for (const groupId in this.editableUser.access.groups) {
            if (
              !this.user.access.groups[groupId] ||
              (this.user.access.groups[groupId] &&
                !this.user.access.groups[groupId].isAdmin)
            ) {
              const group = this.editableUser.access.groups[groupId];
              adminGroups.push({
                name: group.name,
                value: { _id: groupId, ...group },
              });
            }
          }
        }
        return adminGroups;
      }
    },
    roles() {
      if (this.user.access.accessLevel === "company_admin") {
        return [
          { name: this.$t("User"), value: "broker" },
          { name: this.$t("GroupAdmin"), value: "group_admin" },
          { name: "Admin", value: "admin" },
        ];
      } else if (this.user.access.accessLevel === "group_admin") {
        return [
          { name: this.$t("User"), value: "broker" },
          { name: this.$t("GroupAdmin"), value: "group_admin" },
        ];
      }
      return [];
    },
    groupAdminSelection() {
      // adminGroups.forEach((group) => (group.selected = false));
      let possibleGroups = [];
      if (this.user.access.accessLevel !== "company_admin") {
        for (const group of this.newUser.groups) {
          if (
            this.user.access.groups[group.value._id] &&
            this.user.access.groups[group.value._id].isAdmin
          ) {
            possibleGroups.push(group);
          }
        }
      } else {
        possibleGroups = this.newUser.groups;
      }
      return possibleGroups;
    },
  },
  watch: {
    'newUser.groups': function(groups) {
      this.newUser.groups = groups; 
      if (!this.newUser.groups.find((group) => group.value._id === this.defaultGroup.value._id)) {
        this.defaultGroup = null;
      }
      if (this.groupAdminGroups.length) {
        let groupToRemove = this.groupAdminGroups.filter(({ value: id1 }) => !this.newUser.groups.some(({ value: id2 }) => id2._id === id1._id))
        this.groupAdminGroups.forEach((group, index) => {
          if (group.value._id === groupToRemove[0].value._id) {
            this.groupAdminGroups.splice(index, 1)
          }
        })
      }
    this.groupUpdater++;
    }
  },
  methods: {
    ...mapActions(["getGroups"]),
    async setupEditMode() {
      console.log("SETUP EDIT MODE....")
      await this.getGroups();
      this.newUser.firstName = this.editableUser.firstname;
      this.newUser.lastName = this.editableUser.lastname;
      this.newUser.email = this.editableUser.email;
      this.newUser.phoneNumber = this.editableUser.phoneNumber;
      this.newUser.userStatus = this.editableUser.userStatus;
      for (const group of this.editableUser.groups) {
        const newGroup = {
          name: group.name,
          value: {
            name: group.name,
            _id: group._id.toString(),
            isGroupAdmin: group.isGroupAdmin,
          },
        };
        this.newUser.groups.push(newGroup);
        if (group.userDefault) {
          this.defaultGroup = newGroup;
        }
        if (group.isGroupAdmin) {
          this.groupAdminGroups.push(newGroup);
        }
      }

      if (this.editableUser.listingAccounts) {
        this.editableUser.listingAccounts.forEach((acc) => {
          if (acc.platform === "kv24") {
            this.newUser.listingAccounts[0].email = acc.email;
          }
        });
      }
      if (this.editableUser.personalCode) {
        this.newUser.personalCode = this.editableUser.personalCode;
      }
      if (!this.editableUser.isAdmin && !this.editableUser.isGroupAdmin) {
        this.newUser.role = "broker";
      }
      if (this.editableUser.isGroupAdmin) {
        this.newUser.role = this.roles[1].value;
      }
      if (this.editableUser.isAdmin) {
        this.newUser.role = this.roles[2].value;
      }
      if (this.editableUser.types) {
        this.newUser.types = this.editableUser.types;
      }
      if (this.editableUser.languages) {
        this.newUser.languages = this.editableUser.languages;
      }
      if (this.editableUser.about) {
        this.newUser.about = this.editableUser.about;
      }
      if (this.editableUser.occupation) {
        this.newUser.occupation = this.editableUser.occupation;
      }
      if (this.editableUser.personalWWWPage) {
        this.newUser.personalWWWPage = this.editableUser.personalWWWPage;
      }
      if (this.editableUser.socials) {
        this.newUser.socials.youtube = this.editableUser.socials.youtube;
        this.newUser.socials.instagram = this.editableUser.socials.instagram;
        this.newUser.socials.tiktok = this.editableUser.socials.tiktok;
        this.newUser.socials.facebook = this.editableUser.socials.facebook;
        this.newUser.socials.linkedIn = this.editableUser.socials.linkedIn;
      }
      if (this.editableUser.clients) {
        this.newUser.clients = this.editableUser.clients;
      }
      if (this.editableUser.achievements) {
        this.newUser.achievements = this.editableUser.achievements;
      }

      if (this.editableUser.profilePicture) {
        this.newUser.images = this.editableUser.profilePicture;
      }

      this.updater++;
    },
    closePanel(e) {
      if (!e) {
        this.$emit("closed");
      } else if (
        e.target.id === "overlay" &&
        !this.feedbackOnly &&
        !this.achievementsOnly
      ) {
        this.$emit("closed");
      }
      if (e.target !== e.currentTarget) return;
    },
    putImages(e) {
      this.newUser.images = e;
    },
    changeLanguageValue(language, index) {
      this.newUser.languages[index] = !language * 1;
    },
    selectGroups(selectedGroups) {
      if (this.user.access.accessLevel !== "company_admin") {
        // 1. Add new groups.
        let updatedSelectedGroups = [];
        for (const group of selectedGroups) {
          if (
            this.user.access.groups[group?.value?._id] &&
            this.user.access.groups[group.value._id].isAdmin
          ) {
            updatedSelectedGroups.push(group);
          }
        }
        // 2. Add back groups that can't be removed.
        if (this.editableUser) {
          for (const groupId in this.editableUser.access.groups) {
            if (
              !this.user.access.groups[groupId] ||
              (this.user.access.groups[groupId] &&
                !this.user.access.groups[groupId].isAdmin)
            ) {
              const group = this.companyGroups.find(
                (groupToFind) => groupToFind?.value?._id === groupId
              );
              updatedSelectedGroups.push(group);
            }
          }
        }
        selectedGroups = updatedSelectedGroups;
      }

      let defaultGroup;
      this.newUser.groups = [];
      selectedGroups.forEach((group) => {
        this.newUser.groups.push(group);
        if (group.value._id === this.defaultGroup?.value?._id) {
          defaultGroup = group;
        } else if (!defaultGroup && this.newUser.groups.length === 1) {
          defaultGroup = group;
        }
      });

      if (!defaultGroup) {
        this.defaultGroup = null;
      } else {
        this.defaultGroup = defaultGroup;
      }
      this.groupUpdater++;
    },
    selectGroupAdminGroups(selectedGroups) {
      if (!this.user.isAdmin) {
        let accessGroupIds = this.groups
          .filter((user) => user.adminAccess)
          .map((accessGroup) => accessGroup._id);
        let newSelection = [];
        for (const group of selectedGroups) {
          if (accessGroupIds.includes(group.value._id)) {
            newSelection.push(group);
          } else {
            group.selected = false;
          }
        }
        this.groupAdminGroups = newSelection;
      } else {
        this.groupAdminGroups = selectedGroups;
      }
    },
    updateSelectedItems(selectedItems) {
      this.newUser.groups = selectedItems;
    },
    updateGroupAdminGroups(selectedItems) {
      this.groupAdminGroups = selectedItems;
    },
    async submitUser() {
      if (this.isLoading) return;
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      if (this.required && this.newUser.groups.length < 1) {
        //TODO create error handling (probably with new multi-select component
        this.showErrors = true;
        return;
      }
      this.newUser.groups.forEach((group) => {
        if (group && group.value && !group._id) {
          group._id = group.value._id;
          group.name = group.value.name;
        }
      });
      if (this.edit) {
        await this.updateUser();
      } else {
        await this.addNewUser();
      }
    },
    async updateUser() {
      /*    for (const item in this.newUser) {
        if (this.newUser[item] === "") {
          this.formMessage.text = this.$t("FormErrors.AllFieldsMustBeFilled");
          this.formMessage.color = "red";
          return;
        }
      } */
      this.newUser.groups.forEach((group) => {
        if (group) {
          const userGroup = this.editableUser.groups.find(
            (g) => g._id === group._id
          );
          if (userGroup && userGroup.ordering) {
            group.ordering = userGroup.ordering;
          }
        } else {
          console.log("Group is undefined");
        }
      });

      this.formatGroupsForSubmit(this.newUser.groups);

      this.formMessage.text = "";
      const userToPost = {
        _id: this.editableUser._id,
        firstname: this.newUser.firstName,
        lastname: this.newUser.lastName,
        ...(this.newUser.role === "admin" && { isAdmin: true }),
        ...(this.newUser.role !== "admin" && { isAdmin: false }),
        ...(this.newUser.role === "group_admin" && { isGroupAdmin: true }),
        ...(this.newUser.role !== "group_admin" && { isGroupAdmin: false }),
        email: this.newUser.email,
        companyId: this.user.companyId,
        personalCode: this.newUser.personalCode,
        phoneNumber: this.newUser.phoneNumber,
        groups: this.newUser.groups,
        types: this.newUser.types,
        languages: this.newUser.languages,
        personalWWWPage: this.newUser.personalWWWPage,
        socials: this.newUser.socials,
        clients: this.newUser.clients,
        achievements: this.newUser.achievements,
        about: this.newUser.about,
        occupation: this.newUser.occupation,
        images: this.newUser.images,
        userStatus: this.newUser.userStatus,
        listingAccounts: this.newUser.listingAccounts,
      };

      this.isLoading = true;
      await axios
        .post("/api/update-colleague", userToPost)
        .then((res) => {
          if (res.status > 499) {
            this.toastError(this.$t("ErrorUpdating"));
          }
          if (res.data.status) {
            this.formMessage.text = res.data.status;
            this.formMessage.color = "red";
          }
          if (!res.data.status) {
            this.$emit("closed", true);
            this.formMessage.text = this.$t("FormPass.UserAdded");
            this.formMessage.color = "green";
            this.toastSuccess(
              this.$t("User") + " " + this.$t("Updated").toLowerCase()
            );
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.formMessage.text = this.$t("FormErrors.SomethingWentWrong");
          this.formMessage.color = "red";
          this.toastError(this.$t("ErrorAdding"));
          this.isLoading = true;
        });
    },
    async addNewUser() {
      /* for (const item in this.newUser) {
        if (item !== "images" && this.newUser[item] === "") {
          this.formMessage.text = this.$t("FormErrors.AllFieldsMustBeFilled");
          this.formMessage.color = "red";
          break;
        }
      } */
      this.formatGroupsForSubmit(this.newUser.groups);
      this.formMessage.text = "";
      const userToPost = {
        firstname: this.newUser.firstName,
        lastname: this.newUser.lastName,
        ...(this.newUser.role === "admin" && { isAdmin: true }),
        ...(this.newUser.role !== "admin" && { isAdmin: false }),
        ...(this.newUser.role === "group_admin" && { isGroupAdmin: true }),
        ...(this.newUser.role !== "group_admin" && { isGroupAdmin: false }),
        email: this.newUser.email,
        companyId: this.user.companyId,
        personalCode: this.newUser.personalCode,
        phoneNumber: this.newUser.phoneNumber,
        groups: this.newUser.groups,
        types: this.newUser.types,
        languages: this.newUser.languages,
        personalWWWPage: this.newUser.personalWWWPage,
        socials: this.newUser.socials,
        clients: this.newUser.clients,
        achievements: this.newUser.achievements,
        about: this.newUser.about,
        occupation: this.newUser.occupation,
        images: this.newUser.images,
        listingAccounts: this.newUser.listingAccounts,
      };

      this.isLoading = true;
      await axios
        .post("/api/create-colleague", userToPost)
        .then((res) => {
          if (res.status === 400) {
            this.toastError(this.$t("UserAlreadyAdded"));
          }
          if (res.status > 499) {
            this.toastError(this.$t("ErrorAdding"));
          }
          if (res.data.status) {
            this.formMessage.text = res.data.status;
            this.formMessage.color = "red";
          }
          if (!res.data.status) {
            this.$emit("closed", true);
            this.$emit("userAdded");
            this.formMessage.text = this.$t("FormPass.UserAdded");
            this.formMessage.color = "green";
            this.newUser = {
              firstName: "",
              lastName: "",
              personalCode: "",
              phoneNumber: "",
              email: "",
              groups: [],
              role: "",
              images: [],
            };
            this.toastSuccess(
              this.$t("User") + " " + this.$t("Added").toLowerCase()
            );
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.formMessage.text = this.$t("FormErrors.SomethingWentWrong");
          this.formMessage.color = "red";
          this.toastError(this.$t("ErrorAdding"));
          this.isLoading = false;
        });
    },

    isFormValid() {
      this.errors = this.getEmptyErrors();

      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "groups":
          if (!this.multiSelectValidity) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
          break;
        case "adminGroups":
          if (
            this.groupAdminGroups.length < 1 &&
            this.roles.length &&
            this.newUser.role === this.roles[1].value
          ) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
          break;
        case "types":
          if (this.newUser[fieldName]) {
            if (
              Object.values(this.newUser[fieldName]).every(
                (value) => value.value === false
              ) &&
              !this.feedbackOnly &&
              !this.achievementsOnly &&
              !this.userSettings
            ) {
              this.errors[fieldName] = "FieldRequired";
              return false;
            }
          }
          break;
        case "defaultGroupValue":
          if (!this.defaultGroup) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
          break;
        default:
          if (!this.newUser[fieldName]) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
      }
      return true;
    },
    getEmptyErrors() {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        groups: [],
        role: "",
        adminGroups: "",
        types: "",
        defaultGroupValue: "",
      };
    },
    handleValidity(validity) {
      this.multiSelectValidity = validity;
    },
    formatGroupsForSubmit(userGroups) {
      const returnGroups = [];
      for (const group of userGroups) {
        if (this.defaultGroup && this.defaultGroup.value && group.value) {
          group.userDefault = this.defaultGroup.value._id === group.value._id;
        }
        group.isGroupAdmin = !!this.groupAdminGroups.find(
          (adminGroup) => adminGroup.value._id === group.value._id
        );
        returnGroups.push(group);
      }
      return returnGroups;
    },
  },
};
</script>

<style lang="scss" scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: auto;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  height: 100%;

  .col {
    padding: 0;
  }
}

.panel-wrapper v-text-field {
  background-color: white;
}

.add-user-panel-header {
  max-height: 97px;
  z-index: 11;
  padding: 24px 32px 24px 32px;
  border-bottom: 1px solid #e6e8ec;
  position: fixed;
  width: 560px;
  background: white;
}

.add-user-panel-content {
  margin: 97px 32px 136px 32px;
  border-radius: 8px;
  background-color: #f4f5f7;
  padding: 16px 16px 32px;
}

.add-user-buttons {
  position: fixed;
  z-index: 11;
  bottom: 0;
  height: 112px;
  width: 560px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #e6e8ec;
  padding: 32px 52px 40px 32px;
  background: white;
}

.small-uploader-en {
  width: 120px !important;
}

.small-uploader-et {
  width: 100px !important;
}

.image-uploader {
  //max-height: 32px;
  //max-width: 90px;
  width: 100%;
  border: 1px dashed #000000;
  box-sizing: border-box;
  border-radius: 8px;

  margin: 0;

  ::v-deep .beforeUpload .icon {
    height: 16px;
    width: auto;
    margin: 0 8px;
  }

  ::v-deep .beforeUpload {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;
  }

  ::v-deep .beforeUpload .mainMessage {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.profile-picture-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 12px;
  margin-bottom: 12px;
}

.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;
}

.add-button p {
  color: white;
}

.text-success {
  color: green;
}

.text-error {
  color: red;
}

::v-deep .clearButton {
  display: none;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}
.gray-font {
  color: #75787a;
}
.checkbox-item {
  display: flex;
  align-items: center;
  gap: 12px;
}
::v-deep .small-size-text {
  font-size: 14px;
  line-height: 20px;
}
.required ::after {
  line-height: 12px;
  font-size: 11px;
}
.language-selection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;

  div {
    cursor: pointer;
  }
}
.active-view {
  padding-bottom: 18px;
  border-bottom: 1px solid black;
}
.red-border {
  outline: 2px solid rgb(255, 30, 36);
}
.margin-up {
  margin-top: 12px !important;
}
</style>
